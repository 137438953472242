<template>
  <div>
    <div>
      <button @click="chooseHandler">Choose image</button>
      <button @click="uploadHandler">Upload image</button>
    </div>
    <div>Upload: {{ uploadValue }}</div>
    <div>
      <input
        type="file"
        ref="imageInput"
        style="display: none"
        @change="previewImage"
        accept="image/*"
      />
    </div>
    <div v-if="image != null">
      <img class="preview" height="268" width="356" :src="imageDisplayed" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Test",
  data() {
    return {
      uploadValue: 0,
      imageDisplayed: null,
      image: null
    };
  },
  methods: {
    uploadHandler() {
      console.log("test");
    },
    chooseHandler() {
      this.$refs.imageInput.click();
    },
    previewImage(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("bad files");
      }
      this.image = files[0];
      this.uploadValue = 0;
      this.onUpload();
    },
    onUpload() {
      const metadata = {
        contentType: `${this.image.type}`
      };

      const filename = this.image.name;

      const storageRef = firebase
        .storage()
        .ref(`${this.$store.state.userId}/${filename}`)
        .put(this.image, metadata);

      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.imageDisplayed = url;
            this.$store.dispatch("updateUserData");
          });
        }
      );
    }
  }
};
</script>

<style></style>
